<template>
  <v-app id="login">
    <v-main>
      <v-container
        fluid
        fill-height
        class="grey lighten-5"
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs10
            sm6
            md3
          >
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(login)">
                <v-flex class="mb-4">
                  <v-img
                    :src="require('../assets/logo.svg')"
                    max-width="100%"
                  ></v-img>
                </v-flex>

                <v-flex>
                  <ValidationProvider name="Nombre de Usuario" rules="required" v-slot="{ errors, valid }">
                    <v-text-field
                      filled
                      v-model="username"
                      append-icon=" "
                      label="Nombre de Usuario"
                      required
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex>
                  <ValidationProvider name="Clave de Ingreso" rules="required" v-slot="{ errors, valid }">
                    <v-text-field
                      filled
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="showPassword ? 'text' : 'password'"
                      name="input-10-1"
                      label="Clave de Ingreso"
                      counter
                      @click:append="showPassword = !showPassword"
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex align-self-center class="mt-4">
                  <v-btn type="submit" block @click.prevent="passes(login)" color="primary"
                    dark :loading="loading">INICIAR SESIÓN</v-btn>
                  <v-btn type="button" block to="forgot" color="secondary" text>Olvidé Mis Credenciales</v-btn>
                </v-flex>
              </v-form>
            </ValidationObserver>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    showPassword: false,
    username: '',
    password: '',
  }),
  methods: {
    login: function () {
      // validar this.valid
      this.loading = true

      sessionStorage.removeItem('token')
      sessionStorage.removeItem('razonSocial')

      this.$soapClient.call('login', {
        username: this.username,
        password: this.password,
      })
      .then((response) => {
        let resultado = response.data.resultado

        if (resultado.token) {
          sessionStorage.setItem('token', resultado.token)
          sessionStorage.setItem('razonSocial', resultado.razonSocial)
          this.$store.state.loggedIn = true
          this.$store.state.razonSocial = resultado.razonSocial
          this.$router.replace({name: 'home'})
        }
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.response.data.resultado, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>
  #login .success--text {
      color: #1B5E20 !important;
      caret-color: #1B5E20 !important;
  }

  #login .error--text {
      color: #D32F2F !important;
      caret-color: #D32F2F !important;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;

  }
</style>
