<template>
  <v-app id="forgot">
    <v-main>
      <v-container
        fluid
        fill-height
        class="grey lighten-5"
      >
        <v-layout
          align-center
          justify-center
        >
          <div
            xs10
            sm4
            md3
          >
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(send)">
                <h1 class="blue--text text--darken-3">Recuperar Credenciales</h1>

                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="email"
                    append-icon=" "
                    type="email"
                    label="Email"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <div align-self-center class="mt-2">
                  <v-btn type="submit" block @click.prevent="passes(send)" color="primary"
                    dark :loading="loading" :disabled="loading">ENVIAR</v-btn>
                  <v-btn type="button" block to="login" color="secondary" text>Cancelar</v-btn>
                </div>
              </v-form>
            </ValidationObserver>
          </div>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    email: '',
  }),
  methods: {
    send: function () {
      // validar this.valid
      this.loading = true

      sessionStorage.removeItem('token')

      this.$soapClient.call('olvideClave', {
        email: this.email,
      })
      .then((response) => {
        let resultado = response.data.resultado

        this.$eventHub.$emit('snackbar-message', resultado, 'success')

        this.email = ''

        this.$refs.obs.reset();
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>
  #forgot .success--text {
      color: #1B5E20 !important;
      caret-color: #1B5E20 !important;
  }

  #forgot .error--text {
      color: #D32F2F !important;
      caret-color: #D32F2F !important;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;

  }
</style>
