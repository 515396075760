var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"forgot"}},[_c('v-main',[_c('v-container',{staticClass:"grey lighten-5",attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('div',{attrs:{"xs10":"","sm4":"","md3":""}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.send)}}},[_c('h1',{staticClass:"blue--text text--darken-3"},[_vm._v("Recuperar Credenciales")]),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","append-icon":" ","type":"email","label":"Email","required":"","error-messages":errors,"success":valid},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('div',{staticClass:"mt-2",attrs:{"align-self-center":""}},[_c('v-btn',{attrs:{"type":"submit","block":"","color":"primary","dark":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return passes(_vm.send)}}},[_vm._v("ENVIAR")]),_c('v-btn',{attrs:{"type":"button","block":"","to":"login","color":"secondary","text":""}},[_vm._v("Cancelar")])],1)],1)]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }